/* eslint-disable */

import { generateMessageModal } from "./checkout/modalHelper";
var channelFinderClass = $(".channel-finder");

function showErrorMsg() {
    var errorMsgZip = "Sorry, we do not have information for that ZIP Code, please enter a new ZIP Code";
    generateMessageModal("ZIP Code Error", errorMsgZip);
    $("#messageModal").modal("show");
    $.spinner().stop();
}

function validateZipAndReturnCountry(inputZip) {
    var regexUS = "^\\d{5}$";
    var regexCA = "^[A-Za-z][0-9][A-Za-z][\\s]?[0-9][A-Za-z][0-9]$";

    var patternUS = new RegExp(regexUS);
    var patternCA = new RegExp(regexCA);

    if (patternUS.test(inputZip)) {
        return "USA";
    } else if (patternCA.test(inputZip)) {
        return "CAN";
    } else {
        return "INVALID_PIN_COUNTRY";
    }
}

function findChannelsForCableProviders(cableProviders) {

    var whiteSpace = " ";
    var counterForCallback = 0;

    if (cableProviders.length > 0) {

        var cableHeader = "<h2 class='zcc-provider-type secondary-heading'>Cable Providers</h2>";
        $("#channelfinder").append(cableHeader);
        $("#channelfinder").append("<div class='zcc-provider-cable'>");

        cableProviders.sort(function (element1, element2) {
            if (element1.name > element2.name) {
                return 1;
            } else if (element1.name < element2.name) {
                return -1;
            } else {
                return 0;
            }
        });

        cableProviders.forEach(function (cableProvider, index) {
            var lineUpId = cableProvider.lineupId;
            var apiKey = channelFinderClass.attr("data-channel-finder-key");
            var channelUrl = channelFinderClass.attr("data-channel-finder-url");
            var url = channelUrl + lineUpId + "/channels?api_key=" + apiKey;
            var cableProviderName = cableProvider.name;
            var displayName;
            if (cableProviderName.indexOf(" - Cable") != -1) {
                displayName = cableProviderName.substr(0, cableProviderName.indexOf(" - Cable"));
            } else {
                displayName = cableProviderName;
            }

            var providerInfo = "<div class='zcc-providers provider-" + cableProvider.lineupId + "'>" +
                "<span class='zcc-providers-info'>" +
                "<span class='zcc-provider-name'>" + displayName + "</span>" +
                "<span class='zcc-separator'> - </span>" +
                "<span class='zcc-location'>" + cableProvider.location + "</span></span>";

            $(".zcc-provider-cable").append(providerInfo);

            $.ajax({
                type: "GET",
                url: url,
                success: function (data) {
                    if (data.length > 0) {
                        var ShoplcChannels = $.grep(data, function (channel, index) {
                            return channel.callSign === "SHOPLC";
                        });
                        var channelInfo;
                        if (ShoplcChannels.length > 0) {
                            channelInfo = "<div class='zcc-station-channel-list'>" +
                                "<span class='zcc-station-channel'>" +
                                "<span class='zcc-station'>ShopLC</span>" +
                                "<span class='zcc-channels'>";
                            ShoplcChannels.forEach(function (shoplcChannel) {
                                channelInfo += "<span class='zcc-channel'>" + whiteSpace + "Channel" + whiteSpace + "" +
                                    shoplcChannel.channel + "</span>";
                            });
                            channelInfo += "</span></span></div></div>";
                        } else {
                            channelInfo = "<div class='zcc-station-channel-list'>" +
                                "<span class='zcc-station-channel zcc-station-notavailable'>Not Available</span></div></div>";
                        }
                        $(".provider-" + cableProvider.lineupId).append(channelInfo);

                        counterForCallback++;

                        //Show Data after last Success Callback 
                        if (cableProviders.length === counterForCallback) {
                            $("#channelfinder").show();
                        }
                    }
                }
            });
        });
    }
}

function findChannelsForSatelliteProviders(satelliteProviders) {
    var whiteSpace = " ";
    if (satelliteProviders.length > 0) {
        var satelliteHeader = "<h2 class='zcc-provider-type secondary-heading'>Satellite Providers</h2>";
        $("#channelfinder").append(satelliteHeader);
        $("#channelfinder").append("<div class='zcc-provider-satellite'>");

        satelliteProviders.sort(function (element1, element2) {
            if (element1.name > element2.name) {
                return 1;
            } else if (element1.name < element2.name) {
                return -1;
            } else {
                return 0;
            }
        });

        satelliteProviders.forEach(function (satelliteProvider, index) {
            var lineUpId = satelliteProvider.lineupId;
            var apiKey = channelFinderClass.attr("data-channel-finder-key");
            var channelUrl = channelFinderClass.attr("data-channel-finder-url");
            var url = channelUrl + lineUpId + "/channels?api_key=" + apiKey;
            var satelliteProviderName = satelliteProvider.name;
            var displayName;
            if (satelliteProviderName.indexOf(" - Satellite") != -1) {
                displayName = satelliteProviderName.substr(0, satelliteProviderName.indexOf(" - Satellite"));
            } else {
                displayName = satelliteProviderName;
            }

            var providerInfo = "<div class='zcc-providers provider-" + satelliteProvider.lineupId + "'>" +
                "<span class='zcc-providers-info'>" +
                "<span class='zcc-provider-name'>" + displayName + "</span>" +
                "<span class='zcc-separator'> - </span>" +
                "<span class='zcc-location'>" + satelliteProvider.location + "</span></span>";

            $(".zcc-provider-satellite").append(providerInfo);

            $.ajax({
                type: "GET",
                url: url,
                success: function (data) {
                    if (data.length > 0) {
                        var ShoplcChannels = $.grep(data, function (channel, index) {
                            return channel.callSign === "SHOPLC";
                        });
                        var channelInfo;
                        if (ShoplcChannels.length > 0) {
                            channelInfo = "<div class='zcc-station-channel-list'>" +
                                "<span class='zcc-station-channel'>" +
                                "<span class='zcc-station'>ShopLC</span>" +
                                "<span class='zcc-channels'>";
                            ShoplcChannels.forEach(function (shoplcChannel) {
                                channelInfo += "<span class='zcc-channel'>" + whiteSpace + "Channel" + whiteSpace + "" +
                                    shoplcChannel.channel + "</span>";
                            });
                            channelInfo += "</span></span></div></div>";

                        } else {
                            channelInfo = "<div class='zcc-station-channel-list'>" +
                                "<span class='zcc-station-channel zcc-station-notavailable'>Not Available</span></div></div>";
                        }
                        $(".provider-" + satelliteProvider.lineupId).append(channelInfo);
                    }
                }
            });
        });
    }
}

export default function () {
    $(document).on("click", ".channel-finder-button", function (e) {
        $.spinner().start();
        var inputZip = $("#zccZip").val();
        var country = validateZipAndReturnCountry(inputZip);
        if ($("#channelfinder").length > 0) {
            $("#channelfinder").remove();
        }
        var channelfinderdiv = "<div id='channelfinder'></div>";
        $("#maincontent").append(channelfinderdiv);

        if (country === "INVALID_PIN_COUNTRY") {
            showErrorMsg();
        } else {
            var apiKey = channelFinderClass.attr("data-channel-finder-key");
            var channelUrl = channelFinderClass.attr("data-channel-finder-url");
            var url = channelUrl + "?country=" + country + "&postalCode=" + inputZip + "&api_key=" + apiKey;
            $.ajax({
                type: "GET",
                url: url,
                success: function (data) {
                    if (data.length > 0) {
						var header = "<div class='zcc-head'><span class='zcc-zip'>" + inputZip +
									"</span><span class='zcc-stn'> - Shop LC HD, Shop LC</span></div>";
						
						$("#channelfinder").append(header);
                        var cableProviders = $.grep(data, function (provider, index) {
                            return provider.type === "CABLE";
                        });

                        var satelliteProviders = $.grep(data, function (provider, index) {
                            return provider.type === "SATELLITE";
                        });

                        if (cableProviders.length === 0) {
                            showErrorMsg();
                        } else {
                            findChannelsForCableProviders(cableProviders);
                            findChannelsForSatelliteProviders(satelliteProviders);
                        }
                    } else {
                        showErrorMsg();
                    }
                    $.spinner().stop();
                },
                error: function (data) {
                    generateMessageModal("Error", "Something went wrong");
                    $("#messageModal").modal("show");
                    $.spinner().stop();
                }
            });
        }
    });
};
