module.exports = {
    generateMessageModal: function (title, message) {
        var $messageModal = $("#messageModal");
        if ($messageModal.length !== 0) {
            $messageModal.remove();
        }

        var htmlString = "<!-- Modal -->" +
        "<div class=\"modal auction-modal fade\" id=\"messageModal\" tabindex=\"0\" role=\"dialog\">" +
            "<div class=\"modal-dialog\">" +
                "<!-- Modal content-->" +
                "<div class=\"modal-content\">" +
                    "<div class=\"modal-header\">" +
                        "<h4 class=\"header-title modal-title\">" + title + "</h4>" +
                        "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">" +
                            "<span aria-hidden=\"true\">&times;</span>" +
                        "</button>" +
                    "</div>" +
                    "<div class=\"modal-body\">" +
                        "<p class=\"error-msg\">" + message + "</p>" +
                    "</div>" +
                    "<div class=\"modal-footer\">" +
                        "<button class=\"btn btn-ok\" data-dismiss=\"modal\">OK</button>" +
                    "</div>" +
                "</div>" +
            "</div>" +
        "</div>";

        $("body").append(htmlString);
        return {modalId: "#messageModal"};
    },

    generatePayNowModal: function (title, message) {
        var $messageModal = $("#payNowModal");
        if ($messageModal.length !== 0) {
            $messageModal.remove();
        }

        var htmlString = "<!-- Modal -->" +
        "<div class=\"modal auction-modal fade\" id=\"payNowModal\" tabindex=\"0\" role=\"dialog\">" +
            "<div class=\"modal-dialog\">" +
                "<!-- Modal content-->" +
                "<div class=\"modal-content\">" +
                    "<div class=\"modal-header\">" +
                        "<h4 class=\"header-title modal-title\">" + title + "</h4>" +
                        "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">" +
                            "<span aria-hidden=\"true\">&times;</span>" +
                        "</button>" +
                    "</div>" +
                    "<div class=\"modal-body\">" +
                        "<p class=\"error-msg\">" + message + "</p>" +
                    "</div>" +
                    "<div class=\"modal-footer\">" +
                        "<a class=\"btn btn-paynow pay-now-cta\" href=\"\">Pay Now</a>" +
                    "</div>" +
                "</div>" +
            "</div>" +
        "</div>";

        $("body").append(htmlString);
        $(".pay-now-cta").attr("href", $("#raCartURL").val());
        return {modalId: "#payNowModal"};
    },
    generateBidModal: function (title, message, bid, toSendedBidObjectModal) {
        var $messageModal = $("#bidModal");
        if ($messageModal.length !== 0) {
            $messageModal.remove();
        }
        // amount to bid in the bidModal should be greater than max bid.
        var amountToBid = Number(bid);
        var htmlString = "<!-- Modal -->" +
        "<div class=\"modal auction-modal fade\" id=\"bidModal\" tabindex=\"0\" role=\"dialog\">" +
            "<div class=\"modal-dialog\">" +
                "<!-- Modal content-->" +
                "<div class=\"modal-content\">" +
                    "<div class=\"modal-header\">" +
                        "<h4 class=\"header-title modal-title\">" + title + "</h4>" +
                        "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">" +
                            "<span aria-hidden=\"true\">&times;</span>" +
                        "</button>" +
                    "</div>" +
                    "<div class=\"modal-body\">" +
                        "<p class=\"error-msg\">" + message + "</p>" +
                    "</div>" +
                    "<div class=\"modal-footer\">" +
                        "<button class=\"btn btn-close\" data-dismiss=\"modal\">Close</button>" +
                        "<input class=\"bid-amount\" type=\"number\" id=\"modalBidAmount\" value=\"" + amountToBid + "\"/>" +
                        "<button class=\"btn btn-bid bid-now-pdp\" data-modal-bid=\"true\" data-max-bid=\"false\" data-dismiss=\"modal\">Bid Now</button>" +
                    "</div>" +
                "</div>" +
            "</div>" +
            "<input type=\"hidden\" id=\"auctionidModal\" value=\"" + toSendedBidObjectModal.auctioncode + "\"/>"+
            "<input type=\"hidden\" id=\"stockcodeModal\" value=\"" + toSendedBidObjectModal.stockcode + "\"/>" +
        "</div>";

        $("body").append(htmlString);
        $(".modal-backdrop").remove();
        return {modalId: "#bidModal"};
    },
    generateMaxBidModal: function (customerName, bid, toSendedBidObjectModal, bidData, isBidEqualToMaxBid) {
        var $messageModal = $("#maxBidModal");
        if ($messageModal.length !== 0) {
            $messageModal.remove();
        }

        if (customerName.length > 9) {
            // Truncate the string to 9 characters and add "..."
            customerName = customerName.substring(0, 9) + "...";
        }

        var errorMessage;

        if (isBidEqualToMaxBid) {
            errorMessage = "<p class=\"error-msg\">" + customerName + " has placed Max Bid of the same amout as your bid</p>";
        } else {
            errorMessage = "<p class=\"error-msg\">" + customerName + " has placed Max Bid higher than your bid</p>";
        }

        var htmlString = "<!-- Modal -->" +
        "<div class=\"modal auction-modal fade\" id=\"maxBidModal\" tabindex=\"0\" role=\"dialog\">" +
            "<div class=\"modal-dialog\">" +
                "<!-- Modal content-->" +
                "<div class=\"modal-content\">" +
                    "<div class=\"modal-header\">" +
                        "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">" +
                            "<span aria-hidden=\"true\">&times;</span>" +
                        "</button>" +
                    "</div>" +
                    "<div class=\"modal-body pt-0\">" +
                        errorMessage +
                        "<div class=\"current-bid my-3\">Current Bid: $" + bid + "</div>" +
                        "<div class=\"place-bid-text mb-2\">Increase your bid to win</div>"+
                        "<div class=\"place-bid-buttons-container d-flex justify-content-around\">" +
                            "<button class=\"bid-now-pdp bid-button\" data-max-bid=\"false\" data-modal-bid=\"true\" value="+ bidData.bid1 +">Bid $" + bidData.bid1 + "</button>" +
                            "<button class=\"bid-now-pdp bid-button\" data-max-bid=\"false\" data-modal-bid=\"true\" value="+ bidData.bid2 +">Bid $" + bidData.bid2 + "</button>" +
                        "</div>"+
                        "<div class=\"or-component\">" +
                            "<div class=\"line\"></div>" +
                            "<div class=\"or\">OR</div>" +
                            "<div class=\"line\"></div>" +
                        "</div>" +
                        "<div class=\"place-bid-text mb-2\">Place Max Bid</div>"+
                        "<div class=\"place-max-bid-buttons-container d-flex justify-content-around\">" +
                            "<button class=\"bid-now-pdp bid-button max-bid-button\" data-max-bid=\"true\" data-modal-bid=\"true\" value="+ bidData.maxBid1 +">Max Bid $" + bidData.maxBid1 + "</button>" +
                            "<button class=\"bid-now-pdp bid-button max-bid-button\" data-max-bid=\"true\" data-modal-bid=\"true\" value="+ bidData.maxBid2 +">Max Bid $" + bidData.maxBid2 + "</button>" +
                        "</div>"+
                    "</div>" +
                "</div>" +
            "</div>" +
                "<input type=\"hidden\" id=\"auctionidModal\" value=\"" + toSendedBidObjectModal.auctioncode + "\"/>"+
                "<input type=\"hidden\" id=\"stockcodeModal\" value=\"" + toSendedBidObjectModal.stockcode + "\"/>" +
        "</div>";

        $("body").append(htmlString);
        return {modalId: "#maxBidModal"};
    },
    generateMaxBidErrorModal: function (title, message, bid, toSendedBidObjectModal) {
        var $messageModal = $("#maxBidErrorModal");
        if ($messageModal.length !== 0) {
            $messageModal.remove();
        }
        var htmlString = "<!-- Modal -->" +
        "<div class=\"modal auction-modal fade\" id=\"maxBidErrorModal\" tabindex=\"0\" role=\"dialog\">" +
            "<div class=\"modal-dialog\">" +
                "<!-- Modal content-->" +
                "<div class=\"modal-content\">" +
                    "<div class=\"modal-header\">" +
                        "<h4 class=\"header-title modal-title\">" + title + "</h4>" +
                        "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">" +
                            "<span aria-hidden=\"true\">&times;</span>" +
                        "</button>" +
                    "</div>" +
                    "<div class=\"modal-body\">" +
                        "<p class=\"error-msg\">" + message + "</p>" +
                    "</div>" +
                    "<div class=\"modal-footer\">" +
                        "<button class=\"btn btn-close\" data-dismiss=\"modal\">Close</button>" +
                        "<input class=\"bid-amount\" type=\"number\" id=\"modalBidAmount\" value=\"" + bid + "\"/>" +
                        "<button class=\"btn btn-bid bid-now-pdp\" data-modal-bid=\"true\" data-max-bid=\"false\" data-dismiss=\"modal\">Bid Now</button>" +
                    "</div>" +
                "</div>" +
            "</div>" +
                "<input type=\"hidden\" id=\"auctionidModal\" value=\"" + toSendedBidObjectModal.auctioncode + "\"/>"+
                "<input type=\"hidden\" id=\"stockcodeModal\" value=\"" + toSendedBidObjectModal.stockcode + "\"/>" +
        "</div>";

        $("body").append(htmlString);
        return {modalId: "#maxBidErrorModal"};
    },
    generateMaxProductListModal: function (message, cartUrl) {
        var $messageModal = $("#maxBidModal");
        if ($messageModal.length !== 0) {
            $messageModal.remove();
        }
        var htmlString = "<!-- Modal -->" +
        "<div class=\"modal auction-modal auction-alert-modal fade\" id=\"maxBidModal\" tabindex=\"0\" role=\"dialog\">" +
            "<div class=\"modal-dialog modal-dialog-centered\">" +
                "<!-- Modal content-->" +
                "<div class=\"modal-content\">" +
                    "<div class=\"modal-close-btn\" data-dismiss=\"modal\">"+
                        "<span class=\"cross-icon\"></span>" +
                    "</div>"+
                    "<div class=\"modal-info-block\">" +
                        "<i class=\"fa fa-exclamation-triangle\"></i>" +
                        "<p class=\"error-msg\">" + message + "</p>" +
                    "</div>" +
                    "<div class=\"modal-footer\">" +
                        "<button class=\"btn btn-cancel\" data-dismiss=\"modal\">Cancel</button>" +
                        "<a class=\"btn btn-paynow pay-now-cta\" href=\"" + cartUrl + "\">Continue To Cart</a>" +
                    "</div>" +
                "</div>" +
            "</div>" +
        "</div>";

        $("body").append(htmlString);
        return {modalId: "#maxBidModal"};
    },

    generateBidNowSucessModel: function (title, message) {
        var $messageModal = $("#buyItNowModal");
        if ($messageModal.length !== 0) {
            $messageModal.remove();
        }

        var htmlString = "<!-- Modal -->" +
        "<div class=\"modal auction-modal fade\" id=\"buyItNowModal\" tabindex=\"0\" role=\"dialog\">" +
            "<div class=\"modal-dialog\">" +
                "<!-- Modal content-->" +
                "<div class=\"modal-content\">" +
                    "<div class=\"modal-header\">" +
                        "<h4 class=\"header-title modal-title\">" + title + "</h4>" +
                        "<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">" +
                            "<span aria-hidden=\"true\">&times;</span>" +
                        "</button>" +
                    "</div>" +
                    "<div class=\"modal-body\">" +
                        "<p class=\"error-msg\">" + message + "</p>" +
                    "</div>" +
                    "<div class=\"modal-footer\">" +
                        "<a class=\"btn btn-paynow buy-it-now-model-ok-btn pay-now-cta\">Buy Now</a>" +
                    "</div>" +
                "</div>" +
            "</div>" +
        "</div>";

        $("body").append(htmlString);
        return {modalId: "#buyItNowModal"};
    }
};
