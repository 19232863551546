var Cleave = require("cleave.js").default;
var CryptoJS = require("crypto-js");
var formValidation = require("@sfra/js/components/formValidation");
var dyHelper = require("../dynamicyield/dy");
var gtmHelper = require("../gtm/gtm");
var gaHelper = require("../tracking/googleanalytics");
var createErrorNotification = require("@sfra/js/components/errorNotification");

module.exports = function () {
    const $phoneNumberFields = $(".js-phone-field");

    $phoneNumberFields.each(function () {
        var currentElement = $(this);
        //eslint-disable-next-line
        var cleaveElement = new Cleave(currentElement, { // NOSONAR
            numeral: true,
            numeralThousandsGroupStyle: "none"
        });
    });

    $(document).ready(function () {
        var forgotPassword = urlParam("forgotPassword");
        if (forgotPassword === "true" || forgotPassword === true) {
            $("#requestPasswordResetModal").modal("show");

        }
    });
};

module.exports.registerCustomerAjax = function (e) {
    var form = $(e.currentTarget);
    e.preventDefault();
    var url = form.attr("action");
    var wishlistLogin = $("body").find(".privacy-policy.pt-3").data("source");
    form.spinner().start();
    $("form.registration").trigger("login:register", e);
    $.ajax({
        url: url,
        type: "post",
        dataType: "json",
        data: form.serialize(),
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                formValidation(form, data);
            } else {
                gtmHelper.fireGtmEvent("NewUserRegistartion", { customerNo: data.customerNo });
                gaHelper.fireGAEvent("NEW_USER", {customerId: data.customerNo});
                if ($(".page").data("action") === "Login-Show") {
                    location.href = data.redirectUrl;
                } else {
                    $("#loginModal").modal("hide");
                    $(document).trigger("load-header");
                    if (!wishlistLogin) {
                        location.href = data.checkoutUrl;    
                    }
                }
                var dyForm = {
                    hashedemail: CryptoJS.SHA256(data.hashedemail).toString(CryptoJS.enc.Hex)
                };
                dyHelper.fireDYEvent("NEW_REGISTRATION", dyForm);
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification($(".error-messaging"), err.responseJSON.errorMessage);
            }

            form.spinner().stop();
        }
    });
    return false;
};


/**
 * searches for provided parameter in current path
 * @param {string} name
 * @returns parameter value or null
 */
function urlParam(name) {
    //added eslint ignore because it didn't like \? regex
    //eslint-disable-next-line
    var results = new RegExp("[\?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results == null) {
        return null;
    }
    return decodeURI(results[1]) || 0;
}


//Added Experian Validation for phone val
$("body").on("click", "#registerForm", function () {
    var phoneNumber = $("#phone");
    var country = $("#country").val();
    if (country == "US") {
        country = "USA";
    } else if (country == "CA") {
        country = "CAN";
    }
    if (phoneNumber.val().length >= 10 && resources.prefs.phonevalidation !== "") {
        var confidenceFlag = true;
        var userData = {};
        userData["number"] = phoneNumber.val();
        userData["output_format"] = "NATIONAL";
        userData["cache_value_days"] = 7;
        userData["country_iso"] = country;
        var url = resources.prefs.phonevalidation;
        $.ajax({
            url: url,
            type: "post",
            async: false,
            data: JSON.stringify(userData),
            headers: {
                "Content-Type": "application/json"
            },
            dataType: "json",
            beforeSend: function (request) {
                request.setRequestHeader("Auth-Token", resources.prefs.amsAccessToken);
            },
            success: function (data) {
                if (data.result.confidence === "Verified" && (data.result.phone_type === "Mobile" || data.result.phone_type === "Landline" || data.result.phone_type === "Mobile or landline" || data.result.phone_type === "Personal number")) {
                    phoneNumber.removeClass("is-invalid");
                    phoneNumber.parents(".form-phone-qas").find(".invalid-feedback").text("");
                    confidenceFlag = true;
                } else {
                    phoneNumber.addClass("is-invalid");
                    phoneNumber.parents(".form-phone-qas").find(".invalid-feedback").text(resources.msg.phoneValidError);
                    confidenceFlag = false;
                }
            },
            error: function () {
                phoneNumber.addClass("is-invalid");
                phoneNumber.parents(".form-phone-qas").find(".invalid-feedback").text(resources.msg.phoneValidError);
            }
        });
        if (!confidenceFlag) {
            return confidenceFlag;
        }
    }
});
