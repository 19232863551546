var updateFPCCart = true;
var updateRACart = true;
var formValidation = require("@sfra/js/components/formValidation");
var CryptoJS = require("crypto-js");
var loginHelper = require("../login/loginHelper");
var gtmHelper = require("../../js/gtm/gtm");

module.exports = function () {
    $(document).ready(function () {
        //if we have fastBuyProductID in url - trigger click on fastbuy button
        var fastBuyProductId = urlParam("fastBuyProductId");
        if (fastBuyProductId) {
            $(`.fast-buy-button[data-product-id="${fastBuyProductId}"]`).trigger("fastbuy:login");
        }
        var isFedexEnabled = $(".shipping-options-wrapper").attr("data-is-fedex-enabled");
        var selectedShipping = $("input[name=shippingMethods]:checked");
        var id = selectedShipping.data("shippingId");
        var $message = $(".upsMessage");
        var searchPattern = isFedexEnabled ? new RegExp("^" + "fedex-expedited", "i") : new RegExp("^" + "ups-2nd", "i");
        var UPSShipping = searchPattern.test(id);
        if (!UPSShipping) {
            $message.each(function () {
                if (!$(this).hasClass("d-none")) {
                    $(this).addClass("d-none");
                }
            });
        } else {
            $message.each(function () {
                if ($(this).hasClass("d-none")) {
                    $(this).removeClass("d-none");
                }
            });
        }

        var isRegularCart = $(".regular-cart").length > 0;
        var isBudgetPaySelected = $(".budgetPayCheckbox[value='selected']").is(":checked");
        if (screen.width <= 544 && isRegularCart) {
            var $payPalContent = $(".js_paypal-content").detach();
            $payPalContent.removeClass("col-12");
            $(".mobile-checkout-continue").append($payPalContent);

            if (isBudgetPaySelected) {
                $payPalContent.addClass("d-none")
            }
        }
    });

    $(".popover, .popover-ra").on("click", ".minicart-dynamic-link", function (e) {
        e.preventDefault();
        var $this = $(this);
        var $minicart = $('.minicart');
        var $popover = $minicart.find('.popover');
        var $popoverRA = $minicart.find('.popover-ra');
        var url = $this.attr("href");
        var cartType = $this.attr("data-cart-type");

        if (cartType == "normal") {
            $popoverRA.removeClass("show").css("transition", "none");
            $popover.addClass("show").css("transition", "none");

            if (!updateFPCCart) {
                return;
            }
        } else {
            $popover.removeClass("show").css("transition", "none");
            $popoverRA.addClass("show").css("transition", "none");

            if (!updateRACart) {
                return;
            }

            $popoverRA.empty();
            var $placeholder = $('.minicart-placeholder');
            var $placeholderClone = $placeholder.clone().removeClass('d-none').addClass('raising');
            $popoverRA.append($placeholderClone);
        }

        $.get(url, function (data) {
            if (cartType == "normal") {
                $popover.empty();
                $popover.append(data);
                updateFPCCart = false;
            } else {
                $popoverRA.empty();
                $popoverRA.append(data);
                updateRACart = false;
            }
        });
    });

    $("body").on("submit", "form.guest-checkout-login", function (e) {
        var $form = $(this);
        e.preventDefault();
        var url = $form.attr("action");
        var redirectUrl = $form.data("redirect");

        $form.spinner().start();

        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: $form.serialize(),
            success: function (data) {
                $form.spinner().stop();
                if (!data.success) {
                    formValidation($form, data);
                } else {
                    window.location.href = redirectUrl;
                }
            },
            error: function () {
                $form.spinner().stop();
                window.location.reload();
            }
        });
        return false;
    });

    $("body").on("submit", "form.login", function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr("action");

        form.spinner().start();
        $("form.login").trigger("login:submit", e);
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                    $("form.login").trigger("login:error", data);
                } else {
                    $("form.login").trigger("login:success", data);

                    if (data.redirectToMissingInfo) {
                        location.href = data.redirectUrl;
                    } else if (form.parent().attr("data-fastbuy")) {
                        if (data.queryString) {
                            var string = data.queryString;
                            var continueUrl = location.href;
                            continueUrl += (continueUrl.indexOf("?") !== -1 ? "&" : "?") + string;
                            window.location.href = continueUrl;
                        } else {
                            location.reload();
                        }

                    } else {
                        location.href = data.redirectUrl;
                    }
                    var dyForm = {
                        hashedemail: CryptoJS.SHA256(data.hashedemail).toString(CryptoJS.enc.Hex),
                        customerNo: data.customerNo
                    };
                    var dyHelper = require("../dynamicyield/dy");
                    dyHelper.fireDYEvent("LOGIN", dyForm);
                }
            },
            error: function (data) {
                gtmHelper.fireGtmEvent("Error", {
                    errorMessage: data.responseJSON.errorMessage
                });

                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    $("form.login").trigger("login:error", data);
                    form.spinner().stop();
                }
            }
        });
        return false;
    });

    $("body").on("submit", "#registerModalSection form.registration", function (e) {
        loginHelper.registerCustomerAjax(e);
    });

    $(".auction-cart").on("click", ".selectAuction", function () {
        $(this).parents(".item-checkbox-container").toggleClass("disabled");
        checkAndSendCheckboxValues();
    });

    $(".select-unselect").on("click", function () {
        var value = $(this).data("selectAll");
        $("input:checkbox[name=checkAuction]").each(function () {
            $(this).prop("checked", value);
        });
        checkAndSendCheckboxValues(true);
    });

    $(document).on("click", ".js-trigger-pdp-link", function () {
        window.location.href = $(this).parents(".auction-item").find(".pdp-link").attr("href");
    });

    //save for later link
    $(document).on("click", ".save-for-later", function (e) {
        e.preventDefault();
        var url = $(this).attr("href");
        var uuid = $(this).data("uuid");
        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                if (data.success) {
                    $("body .mainRemoveButton[data-uuid='" + uuid + "']").trigger("SFL:remove");
                    window.location.reload();
                } else {
                    gtmHelper.fireGtmEvent("Error", {
                        errorMessage: data.errorMessage
                    });
                }
            }
        });
    });

    //move save for later item to the basket link
    $(document).on("click", ".move-save-for-later-to-cart", function (e) {
        e.preventDefault();
        var url = $(this).attr("href");

        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                if (data.success) {
                    window.location.reload();
                }
            },
            error: function error(data) {
                gtmHelper.fireGtmEvent("Error", { errorMessage: data.responseJSON.errorMessage });

                var modalResponseHelper = require("../product/modalResponseHelper");
                var modal = modalResponseHelper.generateErrorModal(data.responseJSON);
                if (modal) {
                    $(modal.modalId).modal("show");
                }
            }
        });
    });
    //remove save for later item link
    $(document).on("click", ".remove-save-for-later", function (e) {
        e.preventDefault();
        var url = $(this).data("action");
        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                if (data.success) {
                    window.location.reload();
                }
            }
        });
    });
    $(".save-for-later-quantity-form").on("change", function (e) {
        e.preventDefault();
        var url = $(this).data("action");
        var quantity = $(this).val();
        var uuid = $(this).data("uuid");
        url += "&quantity=" + quantity;
        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                if (data.success) {
                    //update price
                    $(".line-item-total-" + uuid).empty().append(data.subTotal);
                } else {
                    gtmHelper.fireGtmEvent("Error", { errorMessage: data.errorMessage });
                }
            }
        });
    });

    $("body").on("fpcProduct:afterAddToCart", function () {
        updateFPCCart = true;
    });

    $("body").on("raProduct:afterAddToCart", function () {
        updateRACart = true;
    });

    $("body").on("fpcCart:update", function () {
        updateFPCCart = true;
    });

};

/**
 * send ajax to Cart-CheckAuctionItem
 * and update price and button values with
 * response data
 */
function checkAndSendCheckboxValues(selectGlobal) {
    var selectedItems = getSelectedItems();
    var url = $(".selectAuction").data("url") ;
    $.ajax({
        url: url,
        type: "POST",
        data: {selectedItems},
        success: function (data) {
            var $checkoutButtons = $(".checkout-btn-ra");
            var $selectAll = $(".select-unselect");
            var $itemCheckboxes = $(".item-checkbox-container");

            if (data.quantitySelected === 0) {
                $checkoutButtons.addClass("disabled");
                $selectAll.addClass("green");
                $itemCheckboxes.addClass("disabled");
            } else {
                $checkoutButtons.removeClass("disabled");

                if (!data.selectAllButton.selectAll && selectGlobal) {
                    $itemCheckboxes.removeClass("disabled");
                }

                $selectAll.removeClass("green");
            }
            $(".auction-selected-quantity").empty().append(data.quantitySelected);
            $(".sub-total").empty().append(Number(data.subTotal).toFixed(2));
            $(".estimated-savings-ra").empty().append(data.estimatedSave);
            $selectAll.data("selectAll", data.selectAllButton.selectAll);
            $selectAll.empty().append(data.selectAllButton.text);
        }
    });
}

/**
 * Search selected checkbox values
 * and return an array with selected items uuid
 * @returns {Array} selectedItems
 */
function getSelectedItems() {
    var selectedItems = [];
    $("input:checkbox[name=checkAuction]:checked").each(function () {
        selectedItems.push($(this).val());
    });
    return selectedItems;
}

/**
 * searches for provided parameter in current path
 * @param {string} name
 * @returns parameter value or null
 */
function urlParam(name) {
    //added eslint ignore because it didn't like \? regex
    //eslint-disable-next-line
    var results = new RegExp("[\?&]" + name + "=([^&#]*)").exec(window.location.href);
    if (results == null) {
        return null;
    }
    return decodeURI(results[1]) || 0;
}
